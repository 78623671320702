<template>
    <v-row class="ma-0" align="center" justify="center" no-gutters>
        <template v-if="type === 'CC'">
          <v-col cols="10" class="py-3">
            <v-text-field placeholder="Card Name" hide-details="auto" v-model="cardName" outlined
              :disabled="!cardEnabled"></v-text-field>
          </v-col>
          <v-col cols="10" class="py-3">
            <div id="card-number" class="stripe-element-style rounded-lg" :class="{'error-message': card.error !== '',
              'payment-hover': card.error === '', 'payment-focus':  card.focus}"></div>
            <div v-if="card.error !== ''" class="pa-0 error--text">
              {{ card.error }}
            </div>
          </v-col>
          <v-col cols="5" class="py-3 mr-7">
            <div id="card-expiry" class="stripe-element-style rounded-lg" :class="{'error-message': expiry.error !== '',
            'payment-hover': expiry.error === '', 'payment-focus':  expiry.focus}"></div>
            <div v-if="expiry.error !== ''" class="pa-0 error--text">
              {{ expiry.error }}
            </div>
          </v-col>
          <v-col cols="4" class="py-3 ml-7">
            <div id="card-cvc" class="stripe-element-style rounded-lg" :class="{'error-message': cvc.error !== '',
              'payment-hover': cvc.error === '', 'payment-focus':  cvc.focus}"></div>
            <div v-if="cvc.error !== ''" class="pa-0 error--text">
              {{ cvc.error }}
            </div>
          </v-col>
        </template>
        <template v-if="type === 'DD'">
          <v-col cols="10" class="mt-3">
              <v-text-field outlined @input="nameType()" :error="stripeErr !== '' && stripeErr.indexOf('billing_details[name]') !== -1"
              placeholder="Account Name" v-model="accName" :disabled="!cardEnabled" rounded-lg></v-text-field>
              <div id="au-bank-account-element" class="stripe-element-style rounded-lg" :class="{'error-message': becsAcc.error !== '',
              'payment-hover': becsAcc.error === '', 'payment-focus':  becsAcc.focus}"></div>
              <div v-if="becsAcc.error !== ''" class="pa-0 error--text">
              {{ becsAcc.error }}
              </div>
          </v-col>
        </template>
        <template v-if="type === 'DD' && !cardEnabled">
          <v-col cols="12" class="pa-0 pb-2">
            <v-text-field :disabled="true" hide-details="auto" placeholder="Account Name" v-model="accName"></v-text-field>
          </v-col>
          <v-col cols="6" class="pt-0 pb-2 pl-0">
            <v-text-field :disabled="true" hide-details="auto" placeholder="BSC" v-model="selectedExistingCards.bsb"></v-text-field>
          </v-col>
          <v-col cols="6" class="pt-0 pb-2 pr-0">
            <v-text-field :disabled="true" hide-details="auto" placeholder="Account Number" v-model="selectedExistingCards.accNumber"></v-text-field>
          </v-col>
        </template>
        <v-col cols="12" class="pa-0">
           <div v-if="model.stripeErr !== ''" class="pa-0 error--text">
              {{ model.stripeErr }}
            </div>
        </v-col>
    </v-row>
  </template>
  <script>
  import { mapGetters } from "vuex";
  import _ from 'lodash';

  export default {
    name: "Stripe",
    props: ["type", "existingCards", "stripe"],
    emits: ["updatePayDetails"],
    data() {
      return {
        cardEnabled: true,
        cardNumber: undefined,
        cardExpiry: undefined,
        cardCvc: undefined,
        cardName: '',
        accName: '',
        auBankAccount: undefined,
        selectedExistingCards: {},
        card: {
          error: '',
          focus: false,
          complete: false
        },
        expiry: {
          error: '',
          focus: false,
          complete: false
        },
        cvc: {
          error: '',
          focus: false,
          complete: false
        },
        becsAcc: {
          error: '',
          focus: false,
          complete: false
        },
        style: {
            style: {
                base: {
                    fontFamily: 'Mont',
                    '::placeholder': {
                        color: '#00000061',
                    },
                    fontSize: '16px',
                    lineHeight: '20px'
                },
                invalid: {
                    color: '#FF0000',
                },
            }
        },
        stripeErr: ''
      }
    },
    computed: {
      ...mapGetters(["model"]),
    },
    mounted () {
      if (this.cardEnabled) {
        this.setUpStripe();
      }
    },
    created() {
      this.nameType = _.debounce(this.nameType, 250);
      this.cardName = this.accName = this.model.first_name && this.model.last_name ? `${this.model.first_name} ${this.model.last_name}` : this.model.receipt_name;
      this.cardEnabled = this.model.newCard;
    },
    watch: {
      'existingCards': function (newValue) {
        if (newValue) {
          if (this.type === 'DD') {
            newValue.accNumber = `xxxxxx${newValue.meta.last4}`;
            newValue.bsb = newValue.meta.bsb_number;
          } else {
            newValue.cardNumber = `xxxx xxxx xxxx ${newValue.meta.last4}`;
            newValue.cvc = 'xxx';
            newValue.cardExpiry = `${newValue.meta.exp_month}/${((newValue.meta.exp_year).toString())}`
          }
          this.selectedExistingCards = newValue;
        } else {
          this.selectedExistingCards = {};
        }
      },
      'type': function (newValue) {
        this.model.stripeErr = '';
        if (this.model.newCard) {
          setTimeout(() => {
            this.setUpStripe();
          });
        }
      },
      'model.newCard': function (newValue) {
        this.model.stripeErr = '';
        this.cardEnabled = newValue;
        if (newValue) {
          setTimeout(() => {
            this.setUpStripe();
          });
        } else {
          this.cardNumber.update({ disabled: true });
          this.cardExpiry.update({ disabled: true });
          this.cardCvc.update({ disabled: true });
        }
      },
      cardName(newValue) {
        if (newValue) this.cardName = this.$filters.titleCase(newValue)
      },
    },
    methods: {
      setUpStripe() {
        if (window.Stripe === undefined) {
          alert('Stripe V3 library not loaded!');
        } else {
          const elements = this.stripe.elements({
              fonts:[
                  {
                  family: 'Mont',
                  src: 'url(/fonts/Mont/Mont-Regular.woff) format(woff)'
                  }
              ]
          });
          if (this.type === 'DD') {
            this.auBankAccount = elements.create('auBankAccount', this.style);
            this.auBankAccount.mount('#au-bank-account-element');
          } else {
            this.cardCvc = elements.create('cardCvc', this.style);
            this.cardExpiry = elements.create('cardExpiry', this.style);
            this.cardNumber = elements.create('cardNumber',  {...this.style, ...{ placeholder: 'Card Number' }});
            this.cardCvc.mount('#card-cvc');
            this.cardExpiry.mount('#card-expiry');
            this.cardNumber.mount('#card-number');
          }
          this.listenForErrors();
        }
      },
      nameType() {
        this.updateModel();
      },
      updateModel() {
        if ((this.type === 'CC' && this.card.complete && this.expiry.complete && this.cvc.complete) ||
          (this.type === 'DD' && this.becsAcc.complete)) {
            const customData = {
              cardName: this.cardName,
              accName: this.accName,
              auBankAccount: this.auBankAccount,
              cardNumber: this.cardNumber,
              cardValidate: true,
            }
            this.$emit('updatePayDetails',customData)
        } else {
            this.$emit('updatePayDetails',{cardValidate: false})
        }
      },
      listenForErrors() {
        if (this.type === 'DD') {
          this.becsAccErrors();
        } else {
          this.cardNumEvent();
          this.cardExpiryEvent();
          this.cardCvcEvent();
        }
      },
      cardNumEvent() {
        const self = this;
        this.cardNumber.addEventListener('change', function (event) {
            self.model.stripeErr = '';
            self.card.error = event.error ? event.error.message : '';
            self.card.complete = event.complete;
            self.updateModel();
        });
        this.cardNumber.addEventListener('focus', function (event) {
            self.card.focus = true;
        });
        this.cardNumber.addEventListener('blur', function (event) {
            self.card.focus = false;
        });
      },
      cardExpiryEvent() {
        const self = this;
        this.cardExpiry.addEventListener('change', function (event) {
            self.model.stripeErr = '';
            self.expiry.error = event.error ? event.error.message : '';
            self.expiry.complete = event.complete;
            self.updateModel();
        });
        this.cardExpiry.addEventListener('focus', function (event) {
            self.expiry.focus = true;
        });
        this.cardExpiry.addEventListener('blur', function (event) {
            self.expiry.focus = false;
        });
      },
      cardCvcEvent() {
        const self = this;
        this.cardCvc.addEventListener('change', function (event) {
            self.model.stripeErr = '';
            self.cvc.error = event.error ? event.error.message : '';
            self.cvc.complete = event.complete;
            self.updateModel();
        });
        this.cardCvc.addEventListener('focus', function (event) {
            self.cvc.focus = true;
        });
        this.cardCvc.addEventListener('blur', function (event) {
            self.cvc.focus = false;
        });
      },
      becsAccErrors() {
        const self = this;
        this.auBankAccount.addEventListener('change', function (event) {
          self.model.stripeErr = '';
          self.becsAcc.error = event.error ? event.error.message : '';
          self.becsAcc.complete = event.complete;
          self.updateModel();
        });
        this.auBankAccount.addEventListener('focus', function (event) {
            self.becsAcc.focus = true;
        });
        this.auBankAccount.addEventListener('blur', function (event) {
            self.becsAcc.focus = false;
        });
      }
    }
  };
  </script>

<style scoped lang="scss">
    .payment-card{
    height: 27px;
    border-bottom-color: #949494;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    }
    .stripe-element-style {
        border: thin solid #a1a1a1 !important;
        height: 55px;
        padding: 16px;
    }
    .stripe-element-style:hover, .stripe-element-focus {
        border-width: 2px !important;
        border-color: var(--v-primary-base) !important;
    }
    .payment-hover:hover, .payment-focus {
        border-bottom-color: var(--v-primary-base) !important;
        border-bottom-width: 3px;
    }
    .error-message {
        border-bottom-color: var(--v-danger-base) !important;
    }
  </style>
